import React from 'react';
import { MdOutlineCalendarMonth, MdAccessTime, MdVerified, MdLocationOn } from 'react-icons/md';
import { EventType } from '@/app/types/types';
import moment from 'moment';
import Link from 'next/link';
import 'moment/locale/de'; // Import the German locale

const TournamentCard = ({ event }: { event: EventType }) => {
  return (
    <div className="flex flex-col shadow-event-box bg-white rounded-2xl mb-4 overflow-hidden w-[320px] md:w-[340px] mx-auto">
      <div className="w-full">
        <img
          className="w-full object-cover h-32 md:h-44"
          src={event.event_image_url || `/files/TournamentCardImg.png`}
          alt="Event"
        />
      </div>

      <div className="relative bg-neutral-200 p-4 py-5 flex flex-col">
        <div className="absolute top-[-22px] left-1/2 shadow-feature-section transform -translate-x-1/2 w-[95%] bg-white rounded-lg p-1 py-2 flex justify-around items-center">
          <div className="flex items-center gap-1">
            <div
              className="rounded-full h-full px-2 py-1 text-xs text-neutral-800"
              style={{ backgroundColor: event.game.color }}
            >
              {event.game.name}
            </div>
          </div>
          <div className="flex items-center gap-1">
            <div className="rounded-full flex h-full px-2 py-1 text-xs text-neutral-800 bg-neutral-200">
              <MdLocationOn fontSize={16} />
              <p className="ml-1">{event.city}</p>
            </div>
          </div>
        </div>

        <div className="flex justify-between items-center text-[10px] md:text-[12px] text-neutral-800 mt-5 md:mt-4">
          <div className="flex items-center gap-1 bg-white px-2 rounded-xl">
            <MdOutlineCalendarMonth fontSize={22} />
            <p className="font-wght-600">
              {event.tournamentStart ? moment(event.tournamentStart).format('DD.MM.YYYY') : '01.12.2024'}
            </p>
          </div>
          <div className="flex items-center gap-1 bg-white rounded-xl px-2">
            <MdAccessTime fontSize={22} />
            <p className="font-wght-600 font-neutral-800">
              {event.tournamentStart ? moment(event.tournamentStart).format('HH:mm') : '19:00'} -{' '}
              {event.tournamentStart
                ? moment(event.tournamentStart).add(event.duration, 'hours').format('HH:mm')
                : '23:00'}
            </p>
          </div>
        </div>

        <hr className="border-t border-gray-300 my-2 md:mt-4 mb-2" />

        <h2 className="text-[16px] md:text-xl text-left mb-2 text-neutral-800 font-wght-600">{event.name}</h2>

        <div className="flex items-center mb-4">
          <Link href={event.locationID ? `/profile/${event.locationID}` : '#'}>
            <img
              className="w-10 h-10 border border-gray-300 rounded-full shadow-md mr-2"
              src={event.image_url ? event.image_url : '/files/host.jpg'}
              alt="Host"
            />
          </Link>
          <Link href={event.locationID ? `/profile/${event.locationID}` : '#'}>
            <div className="flex flex-col text-left">
              <div className="text-xs text-neutral-600">veranstaltet von</div>
              <div className="flex items-center text-[16px] font-wght-500   text-neutral-800">
                {event.location_name ? event.location_name : 'Location Name'}{' '}
                <MdVerified className="ml-1 text-primary" />
              </div>
            </div>
          </Link>
          <div className="text-left leading-[20px] absolute right-5">
            <div className="text-primary text-[14px] md:text-[18px]">
              {event.tournamentStart ? moment(event.tournamentStart).format('MMM') : 'Dez.'}
            </div>
            <div className="text-[18px] md:text-[24px] text-neutral-800">
              {event.tournamentStart ? moment(event.tournamentStart).format('DD') : '01'}
            </div>
            <div className="text-[12px] text-neutral-600">
              {event.tournamentStart ? moment(event.tournamentStart).locale('de').format('dddd') : 'Montag'}
            </div>
          </div>
        </div>

        <div className="flex leading-[18px] bg-neutral-100 w-2/3 pt-2 pb-2 pl-4 rounded-lg">
          <div className="flex flex-row items-start">
            <div className="text-primary   text-[20px] uppercase font-wght-600 text-left inline-block">
              <p>Preis</p>{' '}
              <span className="text-neutral-800 font-wght-600 text-[28px] leading-[50px]">{event.price}€</span>
            </div>
            <div className="text-primary text-[10px] px-1 ml-4">
              <div className="rounded-full bg-[#EDEBFF] text-[12px] font-wght-600 px-2">
                {event.booked === event.event_capacity ? 'ausverkauft' : 'verfügbar'}
              </div>
            </div>
          </div>
        </div>

        {event.occurrenceID ? (
          <Link href={`/events/${event.occurrenceID}`}>
            <div className="mt-3 hover:shadow-button-blue hover:bg-[#3429B3] hover:scale-110 font-wght-600 transition duration-200 ease-out shadow-button-blue rounded-full text-white text-[14px] tracking-wider   py-4 px-10 bg-primary text-left w-fit">
              TURNIERDETAILS
            </div>
          </Link>
        ) : (
          <div className="mt-3 hover:shadow-button-blue hover:bg-[#3429B3] hover:scale-110 font-wght-600 transition duration-200 ease-out shadow-button-blue rounded-full text-white text-[14px] tracking-wider   py-4 px-10 bg-primary text-left w-fit">
            TURNIERDETAILS
          </div>
        )}
      </div>
    </div>
  );
};

export default TournamentCard;
